import { toast } from "react-toastify";
import { v4 } from "uuid";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { db, storage } from "firebaseConfig";

import { FirebaseFile } from "types/files";
import { User, UserRole } from "types/auth";
import { CalendarEvent } from "pages/AcademyPage/tabs/CalendarTabPanel/interfaces";
import {
  StorageReference,
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ref,
} from "firebase/storage";

export const getDbUsers = async () => {
  try {
    const usersCollection = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollection);
    const usersRes = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return usersRes as User[];
  } catch (error) {
    return [];
  }
};

export const getDbUserByEmail = async (emailToCheck) => {
  try {
    if (!emailToCheck) {
      return null;
    }

    const usersRef = collection(db, "users");

    const q = query(usersRef, where("email", "==", emailToCheck));

    const querySnapshot = await getDocs(q);

    let user = null;
    querySnapshot.forEach((doc) => {
      user = doc.data();
    });

    return user as User;
  } catch (error) {
    return null;
  }
};

export const addNewUserToDb = async (
  data: {
    name: string;
    lastName: string;
    email: string;
    isAdmin: boolean;
  },
  callback?: Function
) => {
  try {
    const newData = {
      first_name: data.lastName,
      last_name: data.name,
      email: data.email,
      roles: data.isAdmin
        ? [UserRole.admin, UserRole.teacher]
        : [UserRole.teacher],
    };

    const userRef = doc(db, "users", data.email);

    await setDoc(userRef, newData)
      .then(() => {
        callback?.();

        toast.success("Користувач успішно створений!");
      })
      .catch(() => {
        toast.error("У вас нема прав для створення нового уористувача");
      });
  } catch (e) {
    toast.error("Сталась помилка при створенні");
  }
};

export const removeUserFromDb = async (userId: string, callback?: Function) => {
  const userRef = doc(db, "users", userId);

  try {
    await deleteDoc(userRef).then(() => {
      callback?.();
      toast.success("Користувач успішно видалений!");
    });
  } catch {
    toast.error("Сталась помилка при видаленні");
  }
};

export const removeFileFromStorage = async (
  fileRef: StorageReference,
  callback?: Function
) => {
  try {
    await deleteObject(fileRef).then(() => {
      callback();
      toast.success("Файл був успішно видалений!");
    });
  } catch {
    toast.error("Не вдалося видалти файл");
  }
};

export const addNewEventToDb = async (
  data: CalendarEvent,
  callback?: Function
) => {
  try {
    const eventRef = doc(db, "events", v4());

    await setDoc(eventRef, data)
      .then(() => {
        callback?.();
        toast.success("Подія успішно створений!");
      })
      .catch(() => {
        toast.error("У вас нема прав для створення нової події");
      });
  } catch {
    toast.error("Сталась помилка при створенні");
  }
};

export const getDbEvents = async () => {
  try {
    const eventsCollection = collection(db, "events");
    const eventsSnapshot = await getDocs(eventsCollection);

    const eventsRes = eventsSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    return eventsRes as CalendarEvent[];
  } catch (error) {
    return [];
  }
};

export const getDbFiles = async (setFiles: (files: FirebaseFile[]) => void) => {
  try {
    const filesRef = ref(storage, "/");

    listAll(filesRef)
      .then((response) => {
        const promises = response.items.map((itemRef) => {
          return Promise.all([
            getDownloadURL(itemRef),
            getMetadata(itemRef),
          ]).then(([url, metadata]) => ({ url, metadata }));
        });

        Promise.all(promises)
          .then((filesRes) => {
            setFiles(filesRes);
          })
          .catch(() => []);
      })
      .catch(() => []);
  } catch {
    return [];
  }
};

export const removeEventFromDb = async (
  eventId: string,
  callback?: Function
) => {
  const eventRef = doc(db, "events", eventId);

  try {
    await deleteDoc(eventRef).then(() => {
      callback?.();
      toast.success("Подія успішно видалена!");
    });
  } catch {
    toast.error("Сталась помилка при видаленні");
  }
};
