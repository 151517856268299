import React, { createContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "firebaseConfig";
import { signOutGoogle } from "utils/auth";
import { getDbUserByEmail } from "utils/db";

import { User, UserRole } from "types/auth";

interface UserContextProps {
  user: User;
  isLoggedIn: boolean;
  isAdmin: boolean;
  logOut: Function;
  synchUserByEmail: (email: string) => void;
}

export const UserContext = createContext<UserContextProps>(null);

export const UserProvider = ({ children }) => {
  const isStorageLoggedIn = window.localStorage.getItem("isLoggedIn");

  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(!!isStorageLoggedIn || false);

  const navigate = useNavigate();

  const isAdmin = useMemo(
    () => user?.roles.includes(UserRole.admin),
    [user?.roles]
  );

  const synchUserByEmail = async (email: string, omitMessage?: boolean) => {
    const dbUser = await getDbUserByEmail(email);

    if (!!dbUser) {
      setUser({
        ...dbUser,
      });
      window.localStorage.setItem("isLoggedIn", "1");
      setIsLoggedIn(true);

      if (!omitMessage) {
        navigate("/academy");
        toast.success("Ви успішно увійшли до системи!");
      }
    } else {
      setUser(null);
      setIsLoggedIn(false);

      window.localStorage.removeItem("isLoggedIn");

      !omitMessage &&
        toast.warning("У вас немає доступу. Зв'яжіться з адміністратором.");
    }
  };

  const logOut = () => {
    setUser(null);
    setIsLoggedIn(false);
    signOutGoogle();
    window.localStorage.removeItem("isLoggedIn");
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, ({ email }) =>
      synchUserByEmail(email, true)
    );

    return unsubscribe;
  }, []);

  return (
    <UserContext.Provider
      value={{ user, isLoggedIn, isAdmin, logOut, synchUserByEmail }}
    >
      {children}
    </UserContext.Provider>
  );
};
