import React, { FC, lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import Loader from "components/common/Loader";
import { UserProvider } from "context/UserContext";

import "./styles/main.scss";

const AppLayout = lazy(() => import("layouts/AppLayout"));
const AppRoutes = lazy(() => import("./AppRoutes"));

const App: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <UserProvider>
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </UserProvider>
      </BrowserRouter>
    </Suspense>
  );
};
export default App;
