import React from "react";
import { render } from "react-dom";

import App from "./App";

import "react-lazy-load-image-component/src/effects/blur.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-toastify/dist/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-tabs/style/react-tabs.css";
import "aos/dist/aos.css";

render(<App />, document.getElementById("root"));
